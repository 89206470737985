import swiperStyle from '../sass/common/swiper.scss?inline'

let activeIndex;
export default (id, params) => ({
    id,
    active: 0,
    swiperElement: '',
    init() {
        const swiperEl = document.querySelectorAll(`[data-swiper=${id}]`);
        let activeSlide = 1;
        for (let swiper of swiperEl) {
            const swiperParams = {
                injectStyles: [swiperStyle],
                centeredSlides: false,
                navigation: true,
                pagination: {
                    clickable: true
                },
                ...params,
            }

            Object.assign(swiper, swiperParams);
            activeSlide = 1;

            swiper.addEventListener('slidechange', (event) => {
                this.active = event.detail[0].activeIndex
            });

            try {
                swiper.initialize()
                this.swiperElement = swiper
            } catch (e) {
                console.log(e)
            }
        }

    },

    slideNext() {
        this.swiperElement.swiper.slideNext();
    },

    slidePrev() {
        this.swiperElement.swiper.slidePrev();
    }
})
