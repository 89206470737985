import swiperStyle from '../sass/common/used-vehicle-swiper.scss?inline'

export default (id, params) => ({
    id,
    init() {
        const swiperEl = document.querySelectorAll(`[data-swiper=${id}]`);

        for (let swiper of swiperEl) {
            const swiperParams = {
                injectStyles: [swiperStyle],
                centeredSlides: false,
                navigation: true,
                ...params,
            }

            Object.assign(swiper, swiperParams);

            try {
                swiper.initialize();
            } catch (e) {
                console.log(e)
            }
        }

    }
})
